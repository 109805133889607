import axios from 'axios'

const api = axios.create({
baseURL: 'https://ima.unitdtechnologies.com:2026',
//baseURL: 'http://localhost:2025',

});


// const loginApi = axios.create({
//   baseURL: 'https://art-cause.com:3003'
// });


export default api